import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Test.module.scss";
import PathContext from "../../context/path";
import StatesContext from "../../context/states";
import Bubble from '../Bubble';
import Answer from '../Answer';
import Btn from '../Btn';
const Test = (props) => {
  const path = useContext(PathContext);
  const states = useContext(StatesContext);
  const { id, preActive } = props;
  const { isMobile, data, stage, updateCorrectAnswers, navHandler } = states;
  const refWrap = useRef(null);
  const refImgContainer = useRef(null);
  const refImg = useRef(null);

  const refSlides = useRef(null);
  const refBubbles = useRef([]);
  const [isInit, setInit] = useState(false);
  const [bubbleActive, setBubbleActive] = useState(false);
  const [isAnswered, setAnswered] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);


  useEffect(() => {
    if (Object.keys(data).length === 0 || data[`${stage}`] === undefined) return;
    if (stage === id) {
      setTimeout(() => {
        setInit(true);
      }, 1000);
      
      refWrap.current.scrollTop = 0;
    }
  }, [stage, id, data]);

  useEffect(() => {
    if (isInit) {
      refWrap.current.classList.add(styles.active);
    }
  }, [isInit]);

  useEffect(() => {
    if (!isInit) return;

    setTimeout(() => {
      setBubbleActive(true);

    }, 50);
  }, [isInit, id]);
  // fade change imgs
  useEffect(() => {
    // if (!isInit) return;
    if (Object.keys(data).length === 0 || data[`${id}`] === undefined || !preActive) return;
    const type = data[`${id}`].type;
    const imgContainer = refImgContainer.current;
    const img = refImg.current;

    if (type !== 'test' || data[`${id}`] === undefined) return;
    const imgDataSrc = data[`${id}`].img;

    let src = `${path}/img/${isMobile ? 'mobile/' : ''}${imgDataSrc}.webp`;
    let timer;
    const tempImg = new Image();
    tempImg.src = src;
    if (img.src !== tempImg.src) {
      imgContainer.classList.add(styles.hide);
      timer = setTimeout(() => {
        let isError = false;
        if (isError) return;
        img.src = src;
        img.onload = () => {
          imgContainer.classList.remove(styles.hide);
        };
        img.onerror = () => {
          if (isError) return;
          isError = true;
          src = `${path}/img/placeholder.png`;
          img.src = src;
          imgContainer.classList.remove(styles.hide);
        };
      }, 300);

    } else {
      return;
    }
    return () => {
      clearTimeout(timer);
    }
  }, [data, isInit, isMobile, path, id, preActive]);


  useEffect(() => {
    if (!isAnswered) return;
    setTimeout(() => {
      refSlides.current.classList.add(styles.toggle)
    }, 1000);

  }, [isAnswered]);

  const clickHandler = (index) => {
    if (selectIndex > -1) return;
    setSelectIndex(index);
    setAnswered(true);
    const result = data[id].right === index;
    if (result) {

      updateCorrectAnswers(prev => prev + 1);
    }
  }

  const resetHandler = () => {
    setSelectIndex(-1);
    setAnswered(false);
    setBubbleActive(false);
    setBubbleActive(false);
    refSlides.current.classList.remove(styles.toggle);

    refWrap.current.classList.remove(styles.active);
    setInit(false);
  }
  const clickHanlder = () => {
    navHandler();
    setTimeout(() => {
      resetHandler();
    }, 500);
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.inner}>
        <div className={styles.level}>
          {isInit && data[`${id}`].level !== undefined ?
            data[`${id}`].level
            :
            null
          }
        </div>
        <div className={styles.bubbles}>
          {
            isInit && data[`${id}`] !== undefined ?
              data[`${id}`].dialog.map((element, index) => {
                return <div className={styles.bubble} ref={element => refBubbles.current[index] = element} key={`bubble-${index}`}
                  style={{
                    "width": element.size[isMobile ? "mobile" : "normal"][0],
                    "height": element.size[isMobile ? "mobile" : "normal"][1],
                    "left": element.position[isMobile ? "mobile" : "normal"][0],
                    "top": element.position[isMobile ? "mobile" : "normal"][1]
                  }}>
                  <Bubble type={isMobile? element.type['mobile']: element.type['normal']} orient={element.orient} isActive={bubbleActive} delay={1 * index}>{element.text}</Bubble>
                </div>
              })
              :
              null
          }
        </div>
        {isMobile ?
          null
          :
          <div className={styles.img} ref={refImgContainer}>
            <img src={''} alt="" ref={refImg} />
          </div>
        }
        <div className={styles.info}>
          <h2 className={styles.title}>
            Как ответить
            <div className={styles.title__icon}>
              <img src={`${path}/img/title-i-bg.svg`} alt="" />
              ?
            </div>
          </h2>
          {isMobile ?
            <div className={styles.img} ref={refImgContainer}>
              <img src={''} alt="" ref={refImg} />
            </div>
            :
            null
          }
          <div className={styles.slides} ref={refSlides}>
            <div className={styles.slide}>
              <div className={styles.answers}>
                {Object.keys(data).length > 0 ?
                  [0, 1, 2, 3].map((element) => {
                    return <div className={styles.answer} key={`answer-${element}`} onClick={() => clickHandler(element)}>
                      <Answer type={element} right={data[id].right === element} selected={element === selectIndex}>{data[id].answers[element]}</Answer>
                    </div>
                  })
                  :
                  null
                }
              </div>
            </div>
            <div className={styles.slide}>
              <div className={styles.result}>

                {Object.keys(data).length > 0 && isInit ?
                  <Answer type={4} right={data[id].right === selectIndex} selected={true}>{data[id].results[selectIndex]}</Answer>
                  :
                  null
                }
              </div>

              <div className={styles.btn} onClick={clickHanlder}>
                <Btn>Дальше</Btn>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );

}

export default Test;
