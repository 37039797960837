
import React, { useEffect, useState } from "react";

import PathContext from "./context/path";
import StatesContext from "./context/states";
import Main from './pages/Main';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Support from './components/Support/Support';

const App = () => {

  const basename = '.';

  const [isMobile, setMobile] = useState(false);
  const [stage, setStage] = useState(0);
  const [subStage, setSubStage] = useState([]);
  const [data, setData] = useState({});
  const [numberOfTry, setNumberOfTry] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);

  const updateStage = (number) => setStage(number);
  const updateCorrectAnswers = (number) => setCorrectAnswers(number);
  const updatetNumberOfTry = (number) => setNumberOfTry(number);

  const updateSubStage = (id) => {
    let array = [...subStage];
    array[id] = array[id] !== undefined ? array[id] + 1 : 0;
    setSubStage(array);
  };

  const navHandler = () => {
    if (data[stage].stages !== undefined && subStage[stage] < data[stage].stages.length - 1) {
      updateSubStage(stage);
    } else {
      setStage(prev => prev + 1)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').style.opacity = 1;
    // setTimeout(() => {
    //   setStage(0);
    // }, 1000);

    const fpPromise = FingerprintJS.load();

    fpPromise
      .then(fp => fp.get())
      .then(result => localStorage.setItem('visitorId', result.visitorId));

    fetch(`${basename}/data/data.json?ver=${Math.floor((Math.random() * 0xfffff * 1000000)).toString(16)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.scenes);
        let array = [];
        for (let index = 0; index < Object.keys(data.scenes).length; index++) {
          array[index] = 0;
        }
        setSubStage(array)
      });
  }, []);


  useEffect(() => {
    const handleMobile = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      if (window.outerWidth < 1025 && !isMobile) {
        setMobile(true);
      }
      if (window.outerWidth > 1024 && isMobile) {
        setMobile(false);
        document.documentElement.style.setProperty('--vh', ``);
      }
    }
    handleMobile();
    window.addEventListener('resize', handleMobile);
    return () => {
      document.documentElement.style.setProperty('--vh', ``);
      window.removeEventListener('resize', handleMobile);
    }
  }, [isMobile]);

  const restartStages = () => {
    let array = [];
    for (let index = 0; index < Object.keys(data).length; index++) {
      array[index] = 0;
    }
    setSubStage(array)
  }

  return (
    <StatesContext.Provider value={{ isMobile: isMobile, data: data, stage: stage, subStage: subStage, navHandler: navHandler, numberOfTry: numberOfTry, correctAnswers: correctAnswers, updateCorrectAnswers: updateCorrectAnswers, updateStage: updateStage, updatetNumberOfTry: updatetNumberOfTry, restartStages: restartStages }}>
      <PathContext.Provider value={basename}>
        <div className={'wrap'}>
          {/* <Support /> */}
          <Main />
        </div>
      </PathContext.Provider>
    </StatesContext.Provider>
  );

}


export default App;
