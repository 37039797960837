import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Story.module.scss";
import PathContext from "../../context/path";
import StatesContext from "../../context/states";
import Btn from '../Btn';
import Bubble from '../Bubble';
const Story = (props) => {
  const path = useContext(PathContext);
  const states = useContext(StatesContext);
  const { id, preActive } = props;
  const { isMobile, data, stage, subStage, navHandler } = states;
  const refWrap = useRef(null);
  const refImgContainer = useRef(null);
  const refImg = useRef(null);
  const refImgBg = useRef(null);
  const refScreen = useRef(null);
  const refBubbles = useRef([]);
  const [isInit, setInit] = useState(false);
  const [isTransition, setTransition] = useState(true);
  const [bubblesActive, setBubblesActive] = useState([]);
  const [currentSubStage, setCurrentSubStage] = useState(0);


  useEffect(() => {
    if (Object.keys(data).length === 0 || data[`${stage}`] === undefined || data[`${stage}`].stages === undefined) return;
    if (stage === id) {
      setTimeout(() => {
        setInit(true);
      }, 500);
    }
  }, [stage, id, data]);

  useEffect(() => {
    if (isInit) {
      refWrap.current.classList.add(styles.active);
    }
  }, [isInit]);

  useEffect(() => {
    // if (!isInit) return;
    if (!isTransition || stage !== id) return;
    const imgContainer = refImgContainer.current;
    const position = data[id].stages[currentSubStage].position[isMobile ? "mobile" : "normal"];
    const scale = data[id].stages[currentSubStage].scale[isMobile ? "mobile" : "normal"];
    imgContainer.style.transform = `translate3d(${position[0]}, ${position[1]}, 0) scale(${scale})`;
    imgContainer.style.willChange = `transform`;
    setTimeout(() => {
      setTransition(false);
      imgContainer.style.willChange = ``;
    }, 700);
  }, [currentSubStage, data, isInit, isMobile, isTransition, id, stage]);



  useEffect(() => {
    if (!isInit) return;
    if (isTransition) return;
    const bubbles = refBubbles.current;
    let array = [];
    bubbles.forEach((element, index) => {
      array[index] = true;
    });
    setBubblesActive(array);
  }, [isInit, id, isTransition]);

  useEffect(() => {
    if (!isInit) return;
    if (subStage === currentSubStage || subStage === 0) return;
    // выключение предыдущих баблов
    const bubbles = refBubbles.current;
    let array = [];
    bubbles.forEach((element, index) => {
      array[index] = false;
    });
    setBubblesActive(array);
    refBubbles.current = [];
    setTimeout(() => {
      setCurrentSubStage(subStage[id]);
      setTransition(true);
    }, 500);
  }, [currentSubStage, id, isInit, subStage]);


  // fade change imgs
  useEffect(() => {
    // if (!isInit) return;

    if (Object.keys(data).length === 0 || data[`${id}`] === undefined || !preActive || stage > id) return;
    const type = data[`${id}`].type;
    const imgContainer = refImgContainer.current;
    const img = refImg.current;
    const imgBg = refImgBg.current;

    if (currentSubStage === 0) {
      imgContainer.style.transition = 'none';
    } else {

      imgContainer.style.transition = '';
    }
    if (type !== 'story' || data[`${id}`].stages[currentSubStage] === undefined) return;
    const imgDataSrc = data[`${id}`].stages[currentSubStage].img;

    let src = `${path}/img/${isMobile ? 'mobile/' : ''}${imgDataSrc}.webp`;
    let timer;
    const tempImg = new Image();
    tempImg.src = src;
    if (img.src !== tempImg.src) {
      imgContainer.classList.add(styles.hide);
      timer = setTimeout(() => {
        let isError = false;
        if (isError) return;
        img.src = src;
        img.onload = () => {
          imgContainer.classList.remove(styles.hide);
          setTimeout(() => {
            imgBg.src = src;
            imgBg.style.opacity = 1;
            imgContainer.classList.remove(styles.hide_full);
          }, 300);
        };
        img.onerror = () => {
          if (isError) return;
          isError = true;
          src = `${path}/img/placeholder.png`;
          img.src = src;
          imgContainer.classList.remove(styles.hide);
          setTimeout(() => {
            imgBg.src = src;
            imgContainer.classList.remove(styles.hide_full);
          }, 300);
        };
      }, 300);

    } else {
      return;
    }
    return () => {
      clearTimeout(timer);
    }
  }, [currentSubStage, data, isInit, isMobile, path, id, preActive, stage]);

  const clickHandler = () => {
    if (isTransition) return;
    
    navHandler();
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.level}>
          {isInit && data[`${id}`].level !== undefined ?
            data[`${id}`].level
            :
            null
          }
        </div>
        <div className={styles.bubbles}>
          {
            isInit && data[`${id}`].stages !== undefined ?
              data[`${id}`].stages[currentSubStage].dialog.map((element, index) => {
                return <div className={styles.bubble} ref={element => refBubbles.current[index] = element} key={`bubble-${index}`}
                  style={{
                    "width": element.size[isMobile ? "mobile" : "normal"][0],
                    "height": element.size[isMobile ? "mobile" : "normal"][1],
                    "left": element.position[isMobile ? "mobile" : "normal"][0],
                    "top": isMobile ? '50%' : element.position["normal"][1],
                    "transform": isMobile ? `translateY(calc(50% - ${element.position["mobile"][1]}))` : ''
                  }}>
                  <Bubble type={isMobile ? element.type['mobile'] : element.type['normal']} orient={element.orient} isActive={bubblesActive[index]} delay={0.5 + (0.5 * index)}>{element.text}</Bubble>
                </div>
              })
              :
              null
          }
        </div>
        <div className={styles.btnWrap}>
          <div className={styles.btn} onClick={clickHandler}>
            <Btn>Дальше</Btn>
          </div>

          <div className={styles.plate}>
            <img src={`${path}/img/plate-mobile.webp`} alt="" />
          </div>
        </div>
        <div className={styles.screen} ref={refScreen}>
          <div className={styles.img} ref={refImgContainer}>
            <div className={styles.img__front}>
              <img src={''} alt="" ref={refImg} />
            </div>
            <div className={styles.img__back}>
              <img src={''} alt="" ref={refImgBg} />
            </div>
          </div>
        </div>
        <div className={styles.bg}>
          <picture>
            <source media="(max-width: 1024px)" srcSet={`${path}/img/mobile/card-bg.webp`} />
            <img src={`${path}/img/card-bg.webp`} alt="" />
          </picture>
        </div>
      </div>
    </>
  );

}

export default Story;
