import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./Main.module.scss";
import StatesContext from "../../context/states";
import PathContext from "../../context/path";
import Preloader from '../../components/Preloader/Preloader';
import Card from '../../components/Card';
import Cover from '../../components/Cover';
import Story from '../../components/Story/Story';
import Test from '../../components/Test/Test';
import Form from '../../components/Form';
import Final from '../../components/Final';

const Main = () => {
  const states = useContext(StatesContext);
  const path = useContext(PathContext);
  const { isMobile, stage, navHandler, data, updatetNumberOfTry, updateStage, updateCorrectAnswers, restartStages } = states;
  const refWrap = useRef(null);
  const refCards = useRef([]);

  const [init, setInit] = useState(false);
  const [startTime, setStartTime] = useState(false);
  const [currentStage, setCurrentStage] = useState(-1);
  const [isProjectLoaded, setProjectLoaded] = useState(false);
  const [isTranstion, setTransition] = useState(false);
  const [isFinal, setFinal] = useState(false);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const updateProjectLoaded = () => setProjectLoaded(true);

  useEffect(() => {
    if (!isProjectLoaded || init) return;
    navHandler();
    setInit(true);
  }, [isProjectLoaded, navHandler, init]);


  useEffect(() => {
    if (!isDataLoaded) return;
    const cards = refCards.current;
    const hidePrevCard = (index) => {
      if (index < 0) return;
      cards[index].classList.add(styles.out);
      setTimeout(() => {
        cards[index].classList.add(styles.back);
        cards[index].classList.remove(styles.out);
        setTransition(false);
      }, 600);
      setTimeout(() => {
        cards[index].classList.remove(styles.active);

        cards[index].classList.remove(styles.back);
      }, 1000);
    }

    if (currentStage === stage) return;
    setTransition(true);
    setCurrentStage(stage);
    cards[stage].classList.add(styles.active);
    hidePrevCard(currentStage);
  }, [stage, currentStage, isDataLoaded]);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    let array = [];
    for (const key in data) {
      array.push(data[key]);
    }
    setCurrentData(array);
    setDataLoaded(true);

  }, [data]);

  const startHanlder = () => {

    updatetNumberOfTry(prev => prev + 1);
    localStorage.setItem('startTime', new Date().getTime());

  }
  const restartHandler = () => {

    restartStages();
    setTimeout(() => {
      updateStage(3);
      startHanlder();
      updateCorrectAnswers(0);
    }, 100);
  }
  return (

    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.folder}>
          {currentData.length > 0 ?
            currentData.map((element, index) => {
              let type = element.type;
              let content;
              switch (type) {
                case 'preloader':
                  content = <Preloader isActive={stage === index} updateProjectLoaded={updateProjectLoaded} />;
                  break;
                case 'cover':
                  content = <Cover startHanlder={startHanlder} isActive={stage >= index && !isTranstion} />;
                  break;
                case 'form':
                  content = <Form />;
                  break;
                case 'final':
                  content = <Final isFinal={stage >= index} restartHandler={restartHandler} />;
                  break;
                case 'story':
                  content = <Story id={index} preActive={stage + 1 >= index} />;
                  break;
                case 'test':
                  content = <Test id={index} preActive={stage + 1 >= index} />;
                  break;

                default:
                  break;
              }
              return <div className={styles.card} key={`card-${index}`} ref={element => refCards.current[index] = element}>
                <Card>
                  {content}
                </Card>
              </div>
            })
            :
            null
          }
          {/* <div className={styles.card} ref={element => refCards.current[0] = element}>
            <Card>
            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[1] = element}>
            <Card>
              <Cover isActive={stage >= 1 && !isTranstion} />
            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[2] = element}>
            <Card>

            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[3] = element}>
            <Card>
              <Story id={3} />
            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[4] = element}>
            <Card>
              <Story id={4} />
            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[5] = element}>
            <Card>
              <Test id={5} />
            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[6] = element}>
            <Card>
              <Story id={6} />
            </Card>
          </div>
          <div className={styles.card} ref={element => refCards.current[7] = element}>
            <Card>
              <Test id={7} />
            </Card>
          </div> */}
          <div className={styles.folder__bg}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className={styles.bg}>
          <picture>
            <source media="(max-width: 1024px)" srcSet={`${path}/img/mobile/main-bg.webp`} />
            <img src={`${path}/img/main-bg.webp`} alt="" />
          </picture>
        </div>
      </div>
    </>
  );

}

export default Main;
