import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Cover.module.scss";
import StatesContext from "../../context/states";
import PathContext from "../../context/path";
import Bubble from '../Bubble';
import Btn from '../Btn/Btn';
const Cover = (props) => {
  const path = useContext(PathContext);
  const states = useContext(StatesContext);
  const { isMobile, stage,navHandler } = states;
  const { isActive, startHanlder } = props;
  const refWrap = useRef(null);
  const [activeBubble, setActiveBubble] = useState(false);

  useEffect(() => {
    const wrap = refWrap.current;

    if (isActive) {
      setTimeout(() => {
        wrap.classList.add(styles.active);
      }, 500);
      setTimeout(() => {
        setActiveBubble(true);
      }, 1500);
    }
  }, [isActive]);

  const clickHanlder = ()=>{
    startHanlder();
    navHandler();
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.logo}>
          <img src={`${path}/img/logo.svg`} alt="" />
        </div>
        <h1 className={styles.title}>
          <span className={`${styles.title__word} ${styles.title__word_1}`}>дело</span>
          <span className={`${styles.title__word} ${styles.title__word_2}`}>Быка</span>
          <span className={`${styles.title__word} ${styles.title__word_3}`}>
            <img src={`${path}/img/title-i-bg.svg`} alt="" />
            и
            </span>
          <span className={`${styles.title__word} ${styles.title__word_4}`}>Медведя</span>

        </h1>
        <div className={styles.bg}>

          <div className={styles.bubbles}>
            <div className={`${styles.bubble} ${styles.bubble_1}`}>
              <Bubble type={0} isActive={activeBubble}> История о роскоши, игре<br />и разбитом сердце...</Bubble>
            </div>
            <div className={`${styles.bubble} ${styles.bubble_2}`}>
              <Bubble type={1} orient={['right']} isActive={activeBubble} delay={1}> Дело чрезвычайной<br />важности</Bubble>
            </div>
          </div>
          <picture>
            <source media="(max-width: 1024px)" srcSet={`${path}/img/cover-bg-mobile.webp`} />
            <img src={`${path}/img/cover-bg.webp`} alt="" />
          </picture>
        </div>
        <div className={styles.text}>
        Российская империя, конец 19 века. Детективы Медведь и Бык ищут пропавшие акции богатого биржевика Леопарда. Следуй корпоративным ценностям СПВБ, чтобы поймать преступника и восстановить справедливость
        </div>
        <div className={styles.btn} onClick={clickHanlder}>
          <Btn type={'cover'}>Старт</Btn>
        </div>
      </div>
    </>
  );

}

export default Cover;
