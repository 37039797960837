import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Preloader.module.scss";
import StatesContext from "../../context/states";
import PathContext from "../../context/path";
const Preloader = (props) => {
  const states = useContext(StatesContext);
  const { isActive, updateProjectLoaded } = props;
  const { isMobile } = states;
  const [hide, setHide] = useState(false);
  const [isDelay, setDelay] = useState(true);
  const [percent, setPercent] = useState(0);
  const path = useContext(PathContext);
  const refWrap = useRef(null);

  const refImages = useRef([
    'main-bg.webp',
   'card-bg.webp',
    '1-0.webp',
    '1-1.webp',
    '2-0.webp',
    '2-1.webp',
    '3-0.webp',
    '3-1.webp',
    '3-2.webp',
    '3-3.webp',
    '4-0.webp',
    '4-1.webp',
    '4-2.webp',
    '5-0.webp',
    '5-1.webp',
    '5-2.webp',
    '6-0.webp',
    '6-1.webp',
    '6-2.webp',
    '6-3.webp',
    '6-4.webp',
    '6-5.webp',
    '6-6.webp',
    '6-7.webp',
    '7-0.webp',
    '7-1.webp',
    '7-2.webp',
    '7-3.webp',
    '7-4.webp',
    '7-5.webp',
    '7-6.webp',
    '8-0.webp',
    '9-0.webp',
    '9-1.webp',
    '9-2.webp',
    '9-3.webp',
    '9-4.webp',
  ]);


  const [counter, setCounter] = useState(0);
  const [fullCounter, setFullCounter] = useState(0);

  useEffect(()=>{
    if(!isActive) return;
    
    setTimeout(() => {
      setDelay(false);
    }, 1000);
  },[isActive]);

  useEffect(() => {
    if(!isActive || isDelay) return;
    const images = refImages.current;
    const cacheImages = (images) => {
      const promises = [];
      for (let i = 0; i < images.length; i++) {
        const img = new Image();
        promises.push(
          new Promise((resolve, reject) => {
            img.onload = () => {
              setCounter(prev => prev + 1);
              resolve();
            };
            img.onerror = () => {
              setCounter(prev => prev + 1);
              reject();
            };
            img.src = `${path}/img/${isMobile? 'mobile/' : ''}${images[i]}`;
          })
        );
      }
      return promises;
    }



    Promise.all(cacheImages(images))
      .then(() => {
        // console.log('All images preloaded successfully!');
        // Можно запустить дополнительный код здесь, когда все изображения будут предзагружены
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
        console.log(error);
      });
    setFullCounter(images.length);
  }, [path, isActive, isDelay, isMobile]);

  useEffect(() => {
    if (hide) return;
    if (counter !== 0 && counter === fullCounter) {
      setHide(true);
      setTimeout(() => {
        updateProjectLoaded(true);
      }, 1000);
    }
    if (counter !== 0 && counter <= fullCounter) {
      setPercent(Math.floor(counter / fullCounter * 100));
    }
  }, [counter, fullCounter, updateProjectLoaded, hide, isActive]);




  return (
    <>

      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.text}>Загрузка</div>
        <div className={styles.percent}>{percent}%</div>
      </div>
    </>
  );

}

export default Preloader;
