import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Final.module.scss";
import StatesContext from "../../context/states";
import PathContext from "../../context/path";
import Btn from '../Btn';
const Final = (props) => {
  const states = useContext(StatesContext);
  const path = useContext(PathContext);
  const { isMobile, stage, correctAnswers, timeOfTry, numberOfTry } = states;
  const { isFinal, restartHandler } = props;
  const [data, setData] = useState([]);
  const refWrap = useRef(null);
  const refRating = useRef(null);
  useEffect(() => {

    if (!isFinal) return;
    const formData = new FormData();
    const userName = localStorage.getItem('username')
    const userId = localStorage.getItem('visitorId')
    const startTime = localStorage.getItem('startTime') !== null ? localStorage.getItem('startTime') : new Date().getTime() - 30000;
    const timeOfTry = parseFloat(((new Date().getTime() - parseInt(startTime, 10)) / (1000 * 60)).toFixed(2));
    formData.append("name", userName);
    formData.append("id", userId);
    formData.append("correct_answers", correctAnswers);
    formData.append("time", timeOfTry);
    formData.append("attempts", numberOfTry);

    const sendUserResult = () => {
      fetch('/api/result/', {
        method: "POST",
        body: formData
      }).then((data) => {
        getUserRating();
      }).catch((error) => console.error('Error!', error.message));
    }

    // sendUserResult();

    const getUserRating = () => {

      fetch(`/api/rating/?id=${userId}`, {
        method: "GET",
      }).then((response) => {
        return response.json();
      }).then((data) => {
        setData(data.items);
      }).catch((error) => console.error('Error!', error.message));
    }
    const getRating = () => {

      fetch(`/api/rating/`, {
        method: "GET",
      }).then((response) => {
        return response.json();
      }).then((data) => {
        setData(data.items);
      }).catch((error) => console.error('Error!', error.message));
    }
    if (correctAnswers < 1) {
      getRating();
    } else {
      sendUserResult()
    }
  }, [correctAnswers, isFinal, numberOfTry]);

  useEffect(() => {
    if (!isFinal) {
      setTimeout(() => {

        setData([]);
      }, 1000);
    }else{
      
      refWrap.current.scrollTop = 0;
    }
  }, [isFinal]);
  useEffect(() => {
    if (data.length > 0) {
      refRating.current.classList.add(styles.active);
    } else {
      refRating.current.classList.remove(styles.active);
    }
  }, [data]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.inner}>
          <div className={styles.rating} ref={refRating}>
            <div className={styles.tiles} >
              {data.length > 0 ?
                data.map((element, index) => {
                  const elemIndex = element.index < 10 ? `0${element.index}` : element.index;
                  const score = Math.floor(element.score);
                  let bg = null;
                  if (index === 0) {
                    bg = <div className={styles.tile__bg}>
                      <img src={`${path}/img/final-rating-bg-1.svg`} alt="" />
                    </div>
                  }
                  if (index === data.length - 1) {
                    bg = <div className={styles.tile__bg}>
                      <img src={`${path}/img/final-rating-bg-2.svg`} alt="" />
                    </div>
                  }
                  return <div className={styles.tile} key={`tile-${index}`}>
                    <div className={styles.tile__index}>{elemIndex}</div>
                    <div className={styles.tile__name}>{element.name}</div>
                    <div className={styles.tile__score}>{score}</div>
                    {bg}
                  </div>
                })
                :

                null
              }
            </div>
            <div className={styles.desc}>Рейтинг учитывает скорость прохождения игры, количество правильных ответов и количество попыток, которые были предприняты для достижения лучшего результата.</div>
            {isMobile ?
              <div className={styles.btn} onClick={restartHandler}>
                <Btn type={'final'}>Сыграть снова</Btn>
              </div>
              :
              null
            }
          </div>
          <div className={styles.info}>
            <h2 className={styles.title}>Спасибо, коллега, дело раскрыто!</h2>
            <div className={styles.text}>
              <p>Так держать! В игре ты смог использовать корпоративные ценности и добиться успеха — придерживайся их в реальной жизни для достижения новых высот. </p>
              <p>Найди свое имя в списке лучших детективов!</p>
            </div>
            {isMobile ?
              null
              :
              <div className={styles.btn} onClick={restartHandler}>
                <Btn type={'final'}>Сыграть снова</Btn>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );

}

export default Final;
