import React, { useContext, useEffect, useRef } from "react";
import styles from "./Bubble.module.scss";
import PathContext from "../../context/path";
const Bubble = (props) => {
  const path = useContext(PathContext);
  const refWrap = useRef(null);
  const {type,  orient, isActive, delay=0} = props;

  useEffect(()=>{
    const wrap = refWrap.current;
    wrap.classList = `${styles.wrap}`;
    if(orient !== undefined && orient.length > 0){
      orient.forEach(element => {
        switch (element) {
          case 'right':
            wrap.classList.add(styles.right);
            break;
          case 'top':
            wrap.classList.add(styles.top);
            break;
          case 'desc':
            wrap.classList.add(styles.desc);
            break;
        
          default:
            break;
        }
      });
    }
  },[orient, props.children]);
  
  useEffect(()=>{
    const wrap = refWrap.current;
    if(isActive){
      wrap.classList.add(styles.active);
    }else{
      wrap.classList.remove(styles.active);
    }
  },[isActive]);
  
  return (
    <>
      <div className={styles.wrap} ref={refWrap} style={{'--delay': `${delay}s`}}>
        <div className={styles.inner}>
        <div className={styles.text}>{props.children}</div>
        <div className={styles.bg}>
        <picture>
            <source media="(max-width: 1024px)" srcSet={`${path}/img/bubble/mobile/${type}.svg`} />
            <img src={`${path}/img/bubble/${type}.svg`} alt="" />
          </picture>
        </div>
        </div>
      </div>
    </>
  );

}

export default Bubble;
