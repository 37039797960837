import React, { useContext } from "react";
import styles from "./Card.module.scss";
import PathContext from "../../context/path";
const Card = (props) => {
  const path = useContext(PathContext);
  return (
    <>
      <div className={styles.wrap}>
        {props.children}
        <div className={styles.bg}>
          <picture>
            <source media="(max-width: 1024px)" srcSet={`${path}/img/mobile/card-bg.webp`} />
            <img src={`${path}/img/card-bg.webp`} alt="" />
          </picture>
        </div>
      </div>
    </>
  );

}

export default Card;
