import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Form.module.scss";
import StatesContext from "../../context/states";
import PathContext from "../../context/path";
import Bubble from '../Bubble';
import Btn from '../Btn/Btn';
const Form = (props) => {
  const path = useContext(PathContext);
  const states = useContext(StatesContext);
  const { isMobile, stage, navHandler } = states;
  const { isActive } = props;
  const refInput = useRef(null);
  const refBtn = useRef(null);
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    const btn = refBtn.current;
    if (isValid) {
      btn.classList.add(styles.active);
    } else {
      btn.classList.remove(styles.active);
    }
  }, [isValid]);

  const verifyInput = () => {
    const input = refInput.current;
    const fullNamePattern = /^([A-Za-zА-Яа-яЁё]+[-]?[A-Za-zА-Яа-яЁё]*)( [A-Za-zА-Яа-яЁё]+[-]?[A-Za-zА-Яа-яЁё]*)+$/;
    const trimmedInput = input.value.trim();
    const result = fullNamePattern.test(trimmedInput);
    setValid(result);
    
  }

  const capitalizeFullName = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  
  const clickHandler = () => {
    const input = refInput.current;
    if (!isValid) return;
    localStorage.setItem('username', capitalizeFullName(input.value));
    navHandler();
  }
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.logo}>
          <img src={`${path}/img/logo.svg`} alt="" />
        </div>
        <h2 className={styles.title}>Заполни свои имя и фамилию, чтобы начать игру</h2>
        <input type="text" className={styles.input} placeholder='Впиши имя и фамилию' onChange={verifyInput} ref={refInput} />
        <div className={styles.btn} onClick={clickHandler} ref={refBtn}>
          <Btn >Дальше</Btn>
        </div>
      </div>
    </>
  );

}

export default Form;
